var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { SearchCampaignsCollection, SearchCampaignTermsCollection, SearchCampaignUrlsCollection, } from "@bundles/UIAppBundle/collections";
let SearchCampaignUrlEditForm = class SearchCampaignUrlEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "url",
                label: t("management.search_campaign_urls.fields.url"),
                name: ["url"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "searchTerm",
                label: t("management.search_campaign_urls.fields.searchTerm"),
                name: ["searchTerm"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "status",
                label: t("management.search_campaign_urls.fields.status"),
                name: ["status"],
                required: true,
                render: (props) => {
                    const options = [
                        { value: "ACTIVE", label: "Active" },
                        { value: "PROCESSING", label: "Processing" },
                        { value: "DONE", label: "Done" },
                        { value: "FAILED", label: "Failed" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.search_campaign_urls.fields.status") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.search_campaign_urls.fields.status_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "errorMessage",
                label: t("management.search_campaign_urls.fields.errorMessage"),
                name: ["errorMessage"],
                component: Ant.Input,
            },
            {
                id: "createdAt",
                label: t("management.search_campaign_urls.fields.createdAt"),
                name: ["createdAt"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "searchCampaignId",
                label: t("management.search_campaign_urls.fields.searchCampaign"),
                name: ["searchCampaignId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SearchCampaignsCollection, field: "name", required: true }, void 0) }), void 0)),
            },
            {
                id: "searchCampaignTermId",
                label: t("management.search_campaign_urls.fields.searchCampaignTerm"),
                name: ["searchCampaignTermId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SearchCampaignTermsCollection, field: "searchTerm", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            url: 1,
            searchTerm: 1,
            status: 1,
            errorMessage: 1,
            createdAt: 1,
            searchCampaign: {
                _id: 1,
                name: 1,
            },
            searchCampaignId: 1,
            searchCampaignTerm: {
                _id: 1,
                searchTerm: 1,
            },
            searchCampaignTermId: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.search_campaign_urls.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => SearchCampaignUrlsCollection),
    __metadata("design:type", typeof (_a = typeof SearchCampaignUrlsCollection !== "undefined" && SearchCampaignUrlsCollection) === "function" ? _a : Object)
], SearchCampaignUrlEditForm.prototype, "collection", void 0);
SearchCampaignUrlEditForm = __decorate([
    Service({ transient: true })
], SearchCampaignUrlEditForm);
export { SearchCampaignUrlEditForm };
