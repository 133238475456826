import "./i18n";
import { SEARCH_CAMPAIGNS_LIST as BASE_SEARCH_CAMPAIGNS_LIST, SEARCH_CAMPAIGNS_CREATE as BASE_SEARCH_CAMPAIGNS_CREATE, SEARCH_CAMPAIGNS_EDIT as BASE_SEARCH_CAMPAIGNS_EDIT, SEARCH_CAMPAIGNS_VIEW as BASE_SEARCH_CAMPAIGNS_VIEW, } from "./config/routes";
export const SEARCH_CAMPAIGNS_LIST = {
    ...BASE_SEARCH_CAMPAIGNS_LIST,
};
export const SEARCH_CAMPAIGNS_CREATE = {
    ...BASE_SEARCH_CAMPAIGNS_CREATE,
};
export const SEARCH_CAMPAIGNS_EDIT = {
    ...BASE_SEARCH_CAMPAIGNS_EDIT,
};
export const SEARCH_CAMPAIGNS_VIEW = {
    ...BASE_SEARCH_CAMPAIGNS_VIEW,
};
