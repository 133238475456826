var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { SearchCampaignsCollection, SearchCampaignUrlsCollection, SearchCampaignTermsCollection, SearchCampaignJournalistsCollection, } from "@bundles/UIAppBundle/collections";
let SearchCampaignJournalistCreateForm = class SearchCampaignJournalistCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.search_campaign_journalists.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "hostname",
                label: t("management.search_campaign_journalists.fields.hostname"),
                name: ["hostname"],
                component: Ant.Input,
            },
            {
                id: "status",
                label: t("management.search_campaign_journalists.fields.status"),
                name: ["status"],
                required: true,
                tooltip: t("management.search_campaign_journalists.fields.status_description"),
                render: (props) => {
                    const options = [
                        { value: "ACTIVE", label: "Active" },
                        { value: "PROCESSING", label: "Processing" },
                        { value: "DONE", label: "Done" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: t("management.search_campaign_journalists.fields.status") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.search_campaign_journalists.fields.status_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "email",
                label: t("management.search_campaign_journalists.fields.email"),
                name: ["email"],
                component: Ant.Input,
            },
            {
                id: "confidenceScore",
                label: t("management.search_campaign_journalists.fields.confidenceScore"),
                name: ["confidenceScore"],
                component: Ant.InputNumber,
            },
            {
                id: "isValidated",
                label: t("management.search_campaign_journalists.fields.isValidated"),
                name: ["isValidated"],
                required: true,
                initialValue: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "searchCampaignId",
                label: t("management.search_campaign_journalists.fields.searchCampaign"),
                name: ["searchCampaignId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SearchCampaignsCollection, field: "name", required: true }, void 0) }), void 0)),
            },
            {
                id: "searchCampaignUrlId",
                label: t("management.search_campaign_journalists.fields.searchCampaignUrl"),
                name: ["searchCampaignUrlId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SearchCampaignUrlsCollection, field: "url", required: true }, void 0) }), void 0)),
            },
            {
                id: "searchCampaignTermId",
                label: t("management.search_campaign_journalists.fields.searchCampaignTerm"),
                name: ["searchCampaignTermId"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SearchCampaignTermsCollection, field: "searchTerm", required: true }, void 0) }), void 0)),
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.search_campaign_journalists.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.SEARCH_CAMPAIGN_JOURNALISTS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.SEARCH_CAMPAIGN_JOURNALISTS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.SEARCH_CAMPAIGN_JOURNALISTS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => SearchCampaignJournalistsCollection),
    __metadata("design:type", typeof (_a = typeof SearchCampaignJournalistsCollection !== "undefined" && SearchCampaignJournalistsCollection) === "function" ? _a : Object)
], SearchCampaignJournalistCreateForm.prototype, "collection", void 0);
SearchCampaignJournalistCreateForm = __decorate([
    Service({ transient: true })
], SearchCampaignJournalistCreateForm);
export { SearchCampaignJournalistCreateForm };
