import "./i18n";
import { SEARCH_CAMPAIGN_JOURNALISTS_LIST as BASE_SEARCH_CAMPAIGN_JOURNALISTS_LIST, SEARCH_CAMPAIGN_JOURNALISTS_CREATE as BASE_SEARCH_CAMPAIGN_JOURNALISTS_CREATE, SEARCH_CAMPAIGN_JOURNALISTS_EDIT as BASE_SEARCH_CAMPAIGN_JOURNALISTS_EDIT, SEARCH_CAMPAIGN_JOURNALISTS_VIEW as BASE_SEARCH_CAMPAIGN_JOURNALISTS_VIEW, } from "./config/routes";
export const SEARCH_CAMPAIGN_JOURNALISTS_LIST = {
    ...BASE_SEARCH_CAMPAIGN_JOURNALISTS_LIST,
};
export const SEARCH_CAMPAIGN_JOURNALISTS_CREATE = {
    ...BASE_SEARCH_CAMPAIGN_JOURNALISTS_CREATE,
};
export const SEARCH_CAMPAIGN_JOURNALISTS_EDIT = {
    ...BASE_SEARCH_CAMPAIGN_JOURNALISTS_EDIT,
};
export const SEARCH_CAMPAIGN_JOURNALISTS_VIEW = {
    ...BASE_SEARCH_CAMPAIGN_JOURNALISTS_VIEW,
};
