import "./i18n";
import { SEARCH_CAMPAIGN_URLS_LIST as BASE_SEARCH_CAMPAIGN_URLS_LIST, SEARCH_CAMPAIGN_URLS_CREATE as BASE_SEARCH_CAMPAIGN_URLS_CREATE, SEARCH_CAMPAIGN_URLS_EDIT as BASE_SEARCH_CAMPAIGN_URLS_EDIT, SEARCH_CAMPAIGN_URLS_VIEW as BASE_SEARCH_CAMPAIGN_URLS_VIEW, } from "./config/routes";
export const SEARCH_CAMPAIGN_URLS_LIST = {
    ...BASE_SEARCH_CAMPAIGN_URLS_LIST,
};
export const SEARCH_CAMPAIGN_URLS_CREATE = {
    ...BASE_SEARCH_CAMPAIGN_URLS_CREATE,
};
export const SEARCH_CAMPAIGN_URLS_EDIT = {
    ...BASE_SEARCH_CAMPAIGN_URLS_EDIT,
};
export const SEARCH_CAMPAIGN_URLS_VIEW = {
    ...BASE_SEARCH_CAMPAIGN_URLS_VIEW,
};
