import { SearchCampaignTermsList } from "../components/List/SearchCampaignTermsList";
import { SearchCampaignTermsCreate } from "../components/Create/SearchCampaignTermsCreate";
import { SearchCampaignTermsEdit } from "../components/Edit/SearchCampaignTermsEdit";
import { SearchCampaignTermsView } from "../components/View/SearchCampaignTermsView";
import { SettingFilled } from "@ant-design/icons";
export const SEARCH_CAMPAIGN_TERMS_LIST = {
    path: "/admin/search-campaign-terms",
    component: SearchCampaignTermsList,
    menu: {
        key: "SEARCH_CAMPAIGN_TERMS_LIST",
        label: "management.search_campaign_terms.menu.title",
        icon: SettingFilled,
    },
};
export const SEARCH_CAMPAIGN_TERMS_CREATE = {
    path: "/admin/search-campaign-terms/create",
    component: SearchCampaignTermsCreate,
};
export const SEARCH_CAMPAIGN_TERMS_EDIT = {
    path: "/admin/search-campaign-terms/:id/edit",
    component: SearchCampaignTermsEdit,
};
export const SEARCH_CAMPAIGN_TERMS_VIEW = {
    path: "/admin/search-campaign-terms/:id/view",
    component: SearchCampaignTermsView,
};
