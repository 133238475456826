var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { XViewer } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
let SearchCampaignViewer = class SearchCampaignViewer extends XViewer {
    build() {
        const { UIComponents, router } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "_id",
                label: t("management.search_campaigns.fields._id"),
                dataIndex: ["_id"],
                render: (value) => {
                    const props = {
                        type: "objectId",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "name",
                label: t("management.search_campaigns.fields.name"),
                dataIndex: ["name"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "searchTerms",
                label: t("management.search_campaigns.fields.searchTerms"),
                dataIndex: ["searchTerms"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "status",
                label: t("management.search_campaigns.fields.status"),
                dataIndex: ["status"],
                render: (value) => {
                    const props = {
                        type: "enum",
                        value,
                        labelify: true,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "searchFilterDateStart",
                label: t("management.search_campaigns.fields.searchFilterDateStart"),
                dataIndex: ["searchFilterDateStart"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "searchFilterDateEnd",
                label: t("management.search_campaigns.fields.searchFilterDateEnd"),
                dataIndex: ["searchFilterDateEnd"],
                render: (value) => {
                    const props = {
                        type: "date",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "searchFilterLocation",
                label: t("management.search_campaigns.fields.searchFilterLocation"),
                dataIndex: ["searchFilterLocation"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "searchFilterLanguageCode",
                label: t("management.search_campaigns.fields.searchFilterLanguageCode"),
                dataIndex: ["searchFilterLanguageCode"],
                render: (value) => {
                    const props = {
                        type: "string",
                        value,
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "searchCampaignJournalists",
                label: t("management.search_campaigns.fields.searchCampaignJournalists"),
                dataIndex: ["searchCampaignJournalists"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SEARCH_CAMPAIGN_JOURNALISTS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "name",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "searchCampaignUrls",
                label: t("management.search_campaigns.fields.searchCampaignUrls"),
                dataIndex: ["searchCampaignUrls"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SEARCH_CAMPAIGN_URLS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "url",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
            {
                id: "searchCampaignTerms",
                label: t("management.search_campaigns.fields.searchCampaignTerms"),
                dataIndex: ["searchCampaignTerms"],
                render: (value) => {
                    const props = {
                        type: "relation",
                        value,
                        relation: {
                            path: router.path(Routes.SEARCH_CAMPAIGN_TERMS_VIEW, {
                                params: {
                                    id: value === null || value === void 0 ? void 0 : value._id,
                                },
                            }),
                            dataIndex: "searchTerm",
                        },
                    };
                    return _jsx(UIComponents.AdminListItemRenderer, Object.assign({}, props), void 0);
                },
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            searchTerms: 1,
            status: 1,
            searchFilterDateStart: 1,
            searchFilterDateEnd: 1,
            searchFilterLocation: 1,
            searchFilterLanguageCode: 1,
            searchCampaignJournalists: {
                _id: 1,
                name: 1,
            },
            searchCampaignUrls: {
                _id: 1,
                url: 1,
            },
            searchCampaignTerms: {
                _id: 1,
                searchTerm: 1,
            },
        };
    }
};
SearchCampaignViewer = __decorate([
    Service({ transient: true })
], SearchCampaignViewer);
export { SearchCampaignViewer };
