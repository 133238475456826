import { Collection, } from "@bluelibs/x-ui";
import { SearchCampaignJournalist } from "@root/api.types";
import { SearchCampaignsCollection, SearchCampaignUrlsCollection, SearchCampaignTermsCollection, } from "@bundles/UIAppBundle/collections";
export class SearchCampaignJournalistsCollection extends Collection {
    getName() {
        return "SearchCampaignJournalists";
    }
    getInputs() {
        return {
            insert: "SearchCampaignJournalistInsertInput!",
            update: "SearchCampaignJournalistUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => SearchCampaignsCollection,
                name: "searchCampaign",
                field: "searchCampaignId",
            },
            {
                collection: () => SearchCampaignUrlsCollection,
                name: "searchCampaignUrl",
                field: "searchCampaignUrlId",
            },
            {
                collection: () => SearchCampaignTermsCollection,
                name: "searchCampaignTerm",
                field: "searchCampaignTermId",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
