import { Collection, } from "@bluelibs/x-ui";
import { SearchCampaignTerm } from "@root/api.types";
import { SearchCampaignsCollection, SearchCampaignJournalistsCollection, } from "@bundles/UIAppBundle/collections";
export class SearchCampaignTermsCollection extends Collection {
    getName() {
        return "SearchCampaignTerms";
    }
    getInputs() {
        return {
            insert: "SearchCampaignTermInsertInput!",
            update: "SearchCampaignTermUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => SearchCampaignsCollection,
                name: "searchCampaign",
                field: "searchCampaignId",
            },
            {
                collection: () => SearchCampaignJournalistsCollection,
                name: "searchCampaignJournalist",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {};
    }
}
