var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service, Inject } from "@bluelibs/core";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { SearchCampaignsCollection, } from "@bundles/UIAppBundle/collections";
let SearchCampaignEditForm = class SearchCampaignEditForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.search_campaigns.fields.name"),
                name: ["name"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "searchTerms",
                label: t("management.search_campaigns.fields.searchTerms"),
                name: ["searchTerms"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "searchFilterDateStart",
                label: t("management.search_campaigns.fields.searchFilterDateStart"),
                name: ["searchFilterDateStart"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "searchFilterDateEnd",
                label: t("management.search_campaigns.fields.searchFilterDateEnd"),
                name: ["searchFilterDateEnd"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.DatePicker, { required: true }, void 0) }), void 0)),
            },
            {
                id: "searchFilterLocation",
                label: t("management.search_campaigns.fields.searchFilterLocation"),
                name: ["searchFilterLocation"],
                required: true,
                component: Ant.Input,
            },
            {
                id: "searchFilterLanguageCode",
                label: t("management.search_campaigns.fields.searchFilterLanguageCode"),
                name: ["searchFilterLanguageCode"],
                required: true,
                component: Ant.Input,
            },
        ]);
    }
    static getRequestBody() {
        return {
            _id: 1,
            name: 1,
            searchTerms: 1,
            searchFilterDateStart: 1,
            searchFilterDateEnd: 1,
            searchFilterLocation: 1,
            searchFilterLanguageCode: 1,
        };
    }
    onSubmit(_id, values) {
        const { t } = this.i18n;
        return this.collection
            .updateOne(_id, { $set: values })
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.search_campaigns.edit_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => SearchCampaignsCollection),
    __metadata("design:type", typeof (_a = typeof SearchCampaignsCollection !== "undefined" && SearchCampaignsCollection) === "function" ? _a : Object)
], SearchCampaignEditForm.prototype, "collection", void 0);
SearchCampaignEditForm = __decorate([
    Service({ transient: true })
], SearchCampaignEditForm);
export { SearchCampaignEditForm };
