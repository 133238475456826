import { Collection, } from "@bluelibs/x-ui";
import { SearchCampaignUrl } from "@root/api.types";
import { SearchCampaignsCollection, SearchCampaignJournalistsCollection, SearchCampaignTermsCollection, } from "@bundles/UIAppBundle/collections";
export class SearchCampaignUrlsCollection extends Collection {
    getName() {
        return "SearchCampaignUrls";
    }
    getInputs() {
        return {
            insert: "SearchCampaignUrlInsertInput!",
            update: "SearchCampaignUrlUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => SearchCampaignsCollection,
                name: "searchCampaign",
                field: "searchCampaignId",
            },
            {
                collection: () => SearchCampaignTermsCollection,
                name: "searchCampaignTerm",
                field: "searchCampaignTermId",
            },
            {
                collection: () => SearchCampaignJournalistsCollection,
                name: "searchCampaignJournalist",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            createdAt: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
