import "./i18n";
import { SEARCH_CAMPAIGN_TERMS_LIST as BASE_SEARCH_CAMPAIGN_TERMS_LIST, SEARCH_CAMPAIGN_TERMS_CREATE as BASE_SEARCH_CAMPAIGN_TERMS_CREATE, SEARCH_CAMPAIGN_TERMS_EDIT as BASE_SEARCH_CAMPAIGN_TERMS_EDIT, SEARCH_CAMPAIGN_TERMS_VIEW as BASE_SEARCH_CAMPAIGN_TERMS_VIEW, } from "./config/routes";
export const SEARCH_CAMPAIGN_TERMS_LIST = {
    ...BASE_SEARCH_CAMPAIGN_TERMS_LIST,
};
export const SEARCH_CAMPAIGN_TERMS_CREATE = {
    ...BASE_SEARCH_CAMPAIGN_TERMS_CREATE,
};
export const SEARCH_CAMPAIGN_TERMS_EDIT = {
    ...BASE_SEARCH_CAMPAIGN_TERMS_EDIT,
};
export const SEARCH_CAMPAIGN_TERMS_VIEW = {
    ...BASE_SEARCH_CAMPAIGN_TERMS_VIEW,
};
