export var SearchCampaignJournalistStatus;
(function (SearchCampaignJournalistStatus) {
    SearchCampaignJournalistStatus["ACTIVE"] = "ACTIVE";
    SearchCampaignJournalistStatus["DONE"] = "DONE";
    SearchCampaignJournalistStatus["PROCESSING"] = "PROCESSING";
})(SearchCampaignJournalistStatus || (SearchCampaignJournalistStatus = {}));
export var SearchCampaignStatus;
(function (SearchCampaignStatus) {
    SearchCampaignStatus["ACTIVE"] = "ACTIVE";
    SearchCampaignStatus["CRAWLING"] = "CRAWLING";
    SearchCampaignStatus["DONE"] = "DONE";
    SearchCampaignStatus["SEARCHING"] = "SEARCHING";
})(SearchCampaignStatus || (SearchCampaignStatus = {}));
export var SearchCampaignTermStatus;
(function (SearchCampaignTermStatus) {
    SearchCampaignTermStatus["ACTIVE"] = "ACTIVE";
    SearchCampaignTermStatus["DONE"] = "DONE";
    SearchCampaignTermStatus["PROCESSING"] = "PROCESSING";
})(SearchCampaignTermStatus || (SearchCampaignTermStatus = {}));
export var SearchCampaignUrlStatus;
(function (SearchCampaignUrlStatus) {
    SearchCampaignUrlStatus["ACTIVE"] = "ACTIVE";
    SearchCampaignUrlStatus["DONE"] = "DONE";
    SearchCampaignUrlStatus["FAILED"] = "FAILED";
    SearchCampaignUrlStatus["PROCESSING"] = "PROCESSING";
})(SearchCampaignUrlStatus || (SearchCampaignUrlStatus = {}));
export var SubscriptionEventType;
(function (SubscriptionEventType) {
    SubscriptionEventType["added"] = "added";
    SubscriptionEventType["changed"] = "changed";
    SubscriptionEventType["ready"] = "ready";
    SubscriptionEventType["removed"] = "removed";
})(SubscriptionEventType || (SubscriptionEventType = {}));
export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "ADMIN";
    UserRole["END_CUSTOMER"] = "END_CUSTOMER";
    UserRole["MANAGER"] = "MANAGER";
    UserRole["SALES"] = "SALES";
})(UserRole || (UserRole = {}));
