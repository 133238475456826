import { Collection, } from "@bluelibs/x-ui";
import { SearchCampaign } from "@root/api.types";
import { SearchCampaignJournalistsCollection, SearchCampaignUrlsCollection, SearchCampaignTermsCollection, } from "@bundles/UIAppBundle/collections";
export class SearchCampaignsCollection extends Collection {
    getName() {
        return "SearchCampaigns";
    }
    getInputs() {
        return {
            insert: "SearchCampaignInsertInput!",
            update: "SearchCampaignUpdateInput!",
        };
    }
    // Return here the relations with other configs
    getLinks() {
        return [
            {
                collection: () => SearchCampaignJournalistsCollection,
                name: "searchCampaignJournalists",
            },
            {
                collection: () => SearchCampaignUrlsCollection,
                name: "searchCampaignUrls",
            },
            {
                collection: () => SearchCampaignTermsCollection,
                name: "searchCampaignTerms",
            },
        ];
    }
    // Return here how you want to transform certain fields
    getTransformMap() {
        return {
            searchFilterDateStart: (v) => (v && new Date(v) ? new Date(v) : v),
            searchFilterDateEnd: (v) => (v && new Date(v) ? new Date(v) : v),
        };
    }
}
