import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import * as Ant from "antd";
import { ObjectId } from "@bluelibs/ejson";
import { SearchCampaignUrlsCollection } from "@bundles/UIAppBundle/collections";
import { useData } from "@bluelibs/x-ui";
export function UrlsView(props) {
    const { data, isLoading, error, refetch } = useData(SearchCampaignUrlsCollection, {
        filters: {
            searchCampaignId: new ObjectId(props.searchCampaign._id),
        },
    }, {
        url: 1,
        status: 1,
    });
    if (isLoading) {
        return _jsx(Ant.Spin, {}, void 0);
    }
    return (_jsxs(_Fragment, { children: [_jsx(Ant.Card, { children: _jsx(Ant.Space, Object.assign({ align: "end" }, { children: _jsx(Ant.Button, Object.assign({ onClick: () => {
                            refetch();
                        } }, { children: "Refresh" }), void 0) }), void 0) }, void 0), ",", _jsx(Ant.Card, { children: _jsxs(Ant.Table, Object.assign({ dataSource: data, rowKey: "_id", pagination: { pageSize: 100 } }, { children: [_jsx(Ant.Table.Column, { title: "Status", dataIndex: "status" }, void 0), _jsx(Ant.Table.Column, { title: "URL", dataIndex: "url", render: (url) => (_jsx("a", Object.assign({ href: url, target: "_blank" }, { children: url }), void 0)) }, void 0)] }), void 0) }, void 0), ","] }, void 0));
}
