import { SearchCampaignsList } from "../components/List/SearchCampaignsList";
import { SearchCampaignsCreate } from "../components/Create/SearchCampaignsCreate";
import { SearchCampaignsEdit } from "../components/Edit/SearchCampaignsEdit";
import { SearchCampaignsView } from "../components/View/SearchCampaignsView";
import { SettingFilled } from "@ant-design/icons";
export const SEARCH_CAMPAIGNS_LIST = {
    path: "/admin/search-campaigns",
    component: SearchCampaignsList,
    menu: {
        key: "SEARCH_CAMPAIGNS_LIST",
        label: "management.search_campaigns.menu.title",
        icon: SettingFilled,
    },
};
export const SEARCH_CAMPAIGNS_CREATE = {
    path: "/admin/search-campaigns/create",
    component: SearchCampaignsCreate,
};
export const SEARCH_CAMPAIGNS_EDIT = {
    path: "/admin/search-campaigns/:id/edit",
    component: SearchCampaignsEdit,
};
export const SEARCH_CAMPAIGNS_VIEW = {
    path: "/admin/search-campaigns/:id/view",
    component: SearchCampaignsView,
};
