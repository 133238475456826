import { SearchCampaignJournalistsList } from "../components/List/SearchCampaignJournalistsList";
import { SearchCampaignJournalistsCreate } from "../components/Create/SearchCampaignJournalistsCreate";
import { SearchCampaignJournalistsEdit } from "../components/Edit/SearchCampaignJournalistsEdit";
import { SearchCampaignJournalistsView } from "../components/View/SearchCampaignJournalistsView";
import { SettingFilled } from "@ant-design/icons";
export const SEARCH_CAMPAIGN_JOURNALISTS_LIST = {
    path: "/admin/search-campaign-journalists",
    component: SearchCampaignJournalistsList,
    menu: {
        key: "SEARCH_CAMPAIGN_JOURNALISTS_LIST",
        label: "management.search_campaign_journalists.menu.title",
        icon: SettingFilled,
    },
};
export const SEARCH_CAMPAIGN_JOURNALISTS_CREATE = {
    path: "/admin/search-campaign-journalists/create",
    component: SearchCampaignJournalistsCreate,
};
export const SEARCH_CAMPAIGN_JOURNALISTS_EDIT = {
    path: "/admin/search-campaign-journalists/:id/edit",
    component: SearchCampaignJournalistsEdit,
};
export const SEARCH_CAMPAIGN_JOURNALISTS_VIEW = {
    path: "/admin/search-campaign-journalists/:id/view",
    component: SearchCampaignJournalistsView,
};
