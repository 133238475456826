var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
let SearchCampaignListFiltersForm = class SearchCampaignListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.search_campaigns.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "searchTerms",
                label: t("management.search_campaigns.fields.searchTerms"),
                name: ["searchTerms"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "status",
                label: t("management.search_campaigns.fields.status"),
                name: ["status"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.search_campaigns.fields.status") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "ACTIVE" }, { children: "Active" }), "ACTIVE"), _jsx(Ant.Select.Option, Object.assign({ value: "SEARCHING" }, { children: "Searching" }), "SEARCHING"), _jsx(Ant.Select.Option, Object.assign({ value: "CRAWLING" }, { children: "Crawling" }), "CRAWLING"), _jsx(Ant.Select.Option, Object.assign({ value: "DONE" }, { children: "Done" }), "DONE")] }), void 0) }), void 0)),
            },
            {
                id: "searchFilterDateStart",
                label: t("management.search_campaigns.fields.searchFilterDateStart"),
                name: ["searchFilterDateStart"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "searchFilterDateEnd",
                label: t("management.search_campaigns.fields.searchFilterDateEnd"),
                name: ["searchFilterDateEnd"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.DatePicker.RangePicker, {}, void 0) }), void 0)),
            },
            {
                id: "searchFilterLocation",
                label: t("management.search_campaigns.fields.searchFilterLocation"),
                name: ["searchFilterLocation"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "searchFilterLanguageCode",
                label: t("management.search_campaigns.fields.searchFilterLanguageCode"),
                name: ["searchFilterLanguageCode"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
        ]);
    }
};
SearchCampaignListFiltersForm = __decorate([
    Service({ transient: true })
], SearchCampaignListFiltersForm);
export { SearchCampaignListFiltersForm };
