import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React from "react";
import { ObjectId } from "@bluelibs/ejson";
import * as Ant from "antd";
import { use, useData } from "@bluelibs/x-ui";
import { SearchCampaignJournalistsCollection } from "@bundles/UIAppBundle/collections";
export function JournalistsView(props) {
    const { data, isLoading, error, refetch } = useData(SearchCampaignJournalistsCollection, {
        filters: {
            searchCampaignId: new ObjectId(props.searchCampaign._id),
        },
        options: {
            sort: {
                name: 1,
            },
        },
    }, {
        _id: 1,
        name: 1,
        email: 1,
        status: 1,
        isValidated: 1,
        confidenceScore: 1,
        searchCampaignUrl: {
            url: 1,
        },
    });
    if (isLoading) {
        return _jsx(Ant.Spin, {}, void 0);
    }
    if (!data) {
        return _jsx(Ant.Alert, { message: error.toString(), type: "error" }, void 0);
    }
    return (_jsxs(_Fragment, { children: [_jsx(Ant.Card, { children: _jsxs(Ant.Space, Object.assign({ align: "end" }, { children: [_jsx(Ant.Button, Object.assign({ onClick: () => {
                                refetch();
                            } }, { children: "Refresh" }), void 0), _jsx(Ant.Button, Object.assign({ type: "primary", onClick: () => {
                                exportToCSVandDownload(props.searchCampaign, data);
                            } }, { children: "Export to CSV" }), void 0)] }), void 0) }, void 0), _jsx(Ant.Card, { children: _jsxs(Ant.Table, Object.assign({ dataSource: data, rowKey: "_id" }, { children: [_jsx(Ant.Table.Column, { title: "Validated", dataIndex: "isValidated", render: (value, parent) => {
                                return _jsx(Switcher, { value: value, journalist: parent }, void 0);
                            } }, void 0), _jsx(Ant.Table.Column, { title: "Name", dataIndex: "name" }, void 0), _jsx(Ant.Table.Column, { title: "Email", dataIndex: "email" }, void 0), _jsx(Ant.Table.Column, { title: "Confidence Score", dataIndex: "confidenceScore" }, void 0), _jsx(Ant.Table.Column, { title: "Status", dataIndex: "status" }, void 0), _jsx(Ant.Table.Column, { title: "URL", dataIndex: "searchCampaignUrl.url", render: (url) => (_jsx("a", Object.assign({ href: url, target: "_blank" }, { children: url }), void 0)) }, void 0)] }), void 0) }, void 0), ","] }, void 0));
}
function Switcher({ value, journalist }) {
    const [isChecked, setIsChecked] = React.useState(value);
    const searchCampaignJournalistsCollection = use(SearchCampaignJournalistsCollection);
    return (_jsx(Ant.Switch, { checked: isChecked, onChange: (checked) => {
            // Update the document
            setIsChecked(checked);
            journalist.isValidated = checked;
            searchCampaignJournalistsCollection
                .updateOne(journalist._id, {
                $set: {
                    isValidated: checked,
                },
            })
                .catch((e) => {
                setIsChecked(!checked);
                Ant.notification.error({ message: e.toString() });
            });
        } }, void 0));
}
function exportToCSVandDownload(searchCampaign, searchCampaignJournalists) {
    // This function should be implemented to export the data to CSV and download it
    // The data should be passed as an argument
    // The CSV should contain the following columns: Name, Email, Confidence Score, Validated, Status, URL
    // The CSV should be downloaded by the user
    // The CSV should be named "journalists.csv"
    // The CSV should be formatted as follows:
    // Name,Email,Confidence Score,Validated,Status,URL
    let csvContent = "Name,Email,Confidence Score,Validated,Status,URL\n";
    // unique them by email:
    const uniqueEmails = {};
    searchCampaignJournalists
        .filter((journalist) => journalist.isValidated)
        .forEach((journalist) => {
        if (!uniqueEmails[journalist.email]) {
            csvContent += `${journalist.name},${journalist.email},${journalist.confidenceScore},${journalist.isValidated ? "Yes" : "No"},${journalist.status},${journalist.searchCampaignUrl.url}\n`;
            uniqueEmails[journalist.email] = true;
        }
    });
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${searchCampaign.name}-validated-journalists.csv`;
    a.click();
}
