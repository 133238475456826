var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
var _a;
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { SmileOutlined } from "@ant-design/icons";
import * as Ant from "antd";
import { XForm } from "@bluelibs/x-ui-admin";
import { Routes } from "@bundles/UIAppBundle";
import { Service, Inject } from "@bluelibs/core";
import { features } from "./features";
import { UsersCollection } from "@bundles/UIAppBundle/collections";
let UserCreateForm = class UserCreateForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "roles",
                label: t("management.users.fields.roles"),
                name: ["roles"],
                required: true,
                initialValue: [],
                render: (props) => {
                    const options = [
                        { value: "ADMIN", label: "Admin" },
                        { value: "SALES", label: "Sales" },
                        { value: "MANAGER", label: "Manager" },
                        { value: "END_CUSTOMER", label: "End Customer" },
                    ];
                    return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.users.fields.roles") }, { children: options.map((option) => {
                                var _a;
                                return (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: (_a = t(`management.users.fields.roles_enums.${option.label.toLowerCase()}`)) !== null && _a !== void 0 ? _a : option.label }), option.value));
                            }) }), void 0) }), void 0));
                },
            },
            {
                id: "isEnabled",
                label: t("management.users.fields.isEnabled"),
                name: ["isEnabled"],
                required: true,
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "profile",
                label: t("management.users.fields.profile"),
                name: ["profile"],
                required: true,
                nest: [
                    {
                        id: "firstName",
                        label: t("management.users.fields.profile.firstName"),
                        name: ["profile", "firstName"],
                        required: true,
                        component: Ant.Input,
                    },
                    {
                        id: "lastName",
                        label: t("management.users.fields.profile.lastName"),
                        name: ["profile", "lastName"],
                        required: true,
                        component: Ant.Input,
                    },
                ],
            },
        ]);
    }
    onSubmit(document) {
        const { t } = this.i18n;
        return this.collection
            .insertOne(document)
            .then(({ _id }) => {
            Ant.notification.success({
                message: t("generics.success"),
                description: t("management.users.create_confirmation"),
                icon: _jsx(SmileOutlined, {}, void 0),
            });
            if (features.view) {
                return this.router.go(Routes.USERS_VIEW, {
                    params: {
                        id: _id,
                    },
                });
            }
            if (features.list) {
                return this.router.go(Routes.USERS_LIST);
            }
            if (features.edit) {
                return this.router.go(Routes.USERS_EDIT, {
                    params: {
                        id: _id,
                    },
                });
            }
        })
            .catch((err) => {
            Ant.notification.warn({
                message: t("generics.error"),
                description: t("generics.error_message"),
            });
        });
    }
};
__decorate([
    Inject(() => UsersCollection),
    __metadata("design:type", typeof (_a = typeof UsersCollection !== "undefined" && UsersCollection) === "function" ? _a : Object)
], UserCreateForm.prototype, "collection", void 0);
UserCreateForm = __decorate([
    Service({ transient: true })
], UserCreateForm);
export { UserCreateForm };
