import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Link } from "react-router-dom";
import * as Ant from "antd";
import { Routes } from "@bundles/UIAppBundle";
import { useUIComponents, useRouter, useDataOne, use, useTranslate, ApolloClient } from "@bluelibs/x-ui";
import { ObjectId } from "@bluelibs/ejson";
import { SearchCampaignsCollection } from "@bundles/UIAppBundle/collections";
import { SearchCampaignViewer } from "../../config/SearchCampaignViewer";
import { features } from "../../config/features";
import { UrlsView } from "./UrlsView";
import { JournalistsView } from "./JournalistsView";
import { SearchCampaignStatus } from "@root/api.types";
import { gql } from "@apollo/client";
export function SearchCampaignsView(props) {
    const UIComponents = useUIComponents();
    const router = useRouter();
    const t = useTranslate();
    const collection = use(SearchCampaignsCollection);
    // If you want to benefit of live data features use useLiveData()
    const { data: document, isLoading, error, } = useDataOne(SearchCampaignsCollection, new ObjectId(props.id), SearchCampaignViewer.getRequestBody());
    let content;
    if (isLoading) {
        content = (_jsx(Ant.Space, Object.assign({ align: "center" }, { children: _jsx(Ant.Spin, { size: "large" }, void 0) }), void 0));
    }
    else {
        if (error || document === null) {
            content = _jsx(Ant.Alert, { message: error.toString() || t("generics.error_message"), type: "error" }, void 0);
        }
        else {
            content = _jsx(SearchCampaignsViewComponent, { document: document }, void 0);
        }
    }
    return (_jsxs(UIComponents.AdminLayout, { children: [_jsx(Ant.PageHeader, { title: t("management.search_campaigns.view.header"), onBack: () => window.history.back(), extra: getHeaderActions(collection, router, props.id, document) }, void 0), _jsx(Ant.Card, { children: content }, void 0)] }, void 0));
}
export function SearchCampaignsViewComponent(props) {
    const document = props.document;
    const viewer = use(SearchCampaignViewer, { transient: true });
    viewer.setDocument(document);
    viewer.build();
    const items = [
        {
            key: "1",
            label: "Urls",
            children: _jsx(UrlsView, { searchCampaign: document }, void 0),
        },
        {
            key: "2",
            label: "Journalists",
            children: _jsx(JournalistsView, { searchCampaign: document }, void 0),
        },
    ];
    return (_jsxs(Ant.Space, Object.assign({ direction: "vertical", size: "large" }, { children: [_jsx(Ant.Descriptions, { children: viewer.rest().map((item) => {
                    return (_jsx(Ant.Descriptions.Item, Object.assign({ label: item.label }, { children: viewer.render(item) }), item.id));
                }) }, void 0), _jsxs(Ant.Card, { children: [_jsx(Ant.Tabs, { defaultActiveKey: "1", items: items }, void 0), ";"] }, void 0)] }), void 0));
}
export function getHeaderActions(collection, router, id, searchCampaign) {
    const actions = [];
    const t = useTranslate();
    const apolloClient = use(ApolloClient);
    if (features.edit) {
        actions.push(_jsx(Link, Object.assign({ to: router.path(Routes.SEARCH_CAMPAIGNS_EDIT, {
                params: { id },
            }) }, { children: _jsx(Ant.Button, { children: t("generics.edit") }, void 0) }), "edit"));
    }
    if ((searchCampaign === null || searchCampaign === void 0 ? void 0 : searchCampaign.status) === SearchCampaignStatus.ACTIVE) {
        actions.push(_jsx(Ant.Popconfirm, Object.assign({ title: "Are you sure you want begin this SearchCampaign?", onConfirm: () => {
                apolloClient
                    .mutate({
                    mutation: gql `
                mutation SearchCampaignBegin($input: SearchCampaignBeginInput!) {
                  SearchCampaignBegin(input: $input)
                }
              `,
                    variables: {
                        input: {
                            searchCampaignId: searchCampaign._id,
                        },
                    },
                })
                    .then(() => {
                    Ant.notification.success({
                        message: "Success",
                        description: "You have begun the SearchCampaign",
                    });
                });
            } }, { children: _jsx(Ant.Button, { children: "Begin The Search" }, void 0) }), void 0));
    }
    if (features.delete) {
        actions.push(_jsx(Ant.Popconfirm, Object.assign({ title: "Are you sure you want to delete this SearchCampaign?", onConfirm: () => {
                collection.deleteOne(id).then(() => {
                    router.go(Routes.SEARCH_CAMPAIGNS_LIST);
                    Ant.notification.success({
                        message: "Success",
                        description: "You have deleted the SearchCampaign",
                    });
                });
            } }, { children: _jsx(Ant.Button, Object.assign({ danger: true }, { children: t("generics.delete") }), void 0) }), "delete"));
    }
    const viewRoutePath = router.path(Routes.SEARCH_CAMPAIGNS_VIEW, {
        params: { id },
    });
    return actions;
}
