var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var SearchCampaignCreateForm_1;
import { jsx as _jsx } from "react/jsx-runtime";
import { Service } from "@bluelibs/core";
import { SearchCampaignCreateForm as BaseSearchCampaignCreateForm } from "./SearchCampaignCreateForm.base";
import * as Ant from "antd";
let SearchCampaignCreateForm = SearchCampaignCreateForm_1 = class SearchCampaignCreateForm extends BaseSearchCampaignCreateForm {
    build() {
        super.build();
        SearchCampaignCreateForm_1.enhanceForm(this);
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static enhanceForm(instance) {
        instance.update("searchTerms", {
            component: Ant.Input.TextArea,
        });
        // location should be a Select with the values from locations
        instance.update("searchFilterLocation", {
            render: (props) => {
                const options = [
                    { label: "United States", value: "2840" },
                    { label: "United Kingdom", value: "2826" },
                ];
                return (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Select, Object.assign({ placeholder: "Select a location" }, { children: options.map((option) => (_jsx(Ant.Select.Option, Object.assign({ value: option.value }, { children: option.label }), option.value))) }), void 0) }), void 0));
            },
        });
    }
};
SearchCampaignCreateForm = SearchCampaignCreateForm_1 = __decorate([
    Service({ transient: true })
], SearchCampaignCreateForm);
export { SearchCampaignCreateForm };
