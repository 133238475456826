var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { Service } from "@bluelibs/core";
import { SearchCampaignJournalistEditForm as BaseSearchCampaignJournalistEditForm } from "./SearchCampaignJournalistEditForm.base";
let SearchCampaignJournalistEditForm = class SearchCampaignJournalistEditForm extends BaseSearchCampaignJournalistEditForm {
    build() {
        super.build();
        // Perform additional modifications such as updating rendering functions, labels, description
    }
    static getRequestBody() {
        // You have the ability to modify the request by adding certain fields or relations
        return super.getRequestBody();
    }
};
SearchCampaignJournalistEditForm = __decorate([
    Service({ transient: true })
], SearchCampaignJournalistEditForm);
export { SearchCampaignJournalistEditForm };
