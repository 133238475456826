var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { XForm } from "@bluelibs/x-ui-admin";
import { Service } from "@bluelibs/core";
import * as Ant from "antd";
import { SearchCampaignsCollection, SearchCampaignUrlsCollection, SearchCampaignTermsCollection, } from "@bundles/UIAppBundle/collections";
let SearchCampaignJournalistListFiltersForm = class SearchCampaignJournalistListFiltersForm extends XForm {
    build() {
        const { UIComponents } = this;
        const { t } = this.i18n;
        this.add([
            {
                id: "name",
                label: t("management.search_campaign_journalists.fields.name"),
                name: ["name"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "hostname",
                label: t("management.search_campaign_journalists.fields.hostname"),
                name: ["hostname"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "status",
                label: t("management.search_campaign_journalists.fields.status"),
                name: ["status"],
                tooltip: t("management.search_campaign_journalists.fields.status_description"),
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Select, Object.assign({ mode: "multiple", placeholder: t("management.search_campaign_journalists.fields.status") }, { children: [_jsx(Ant.Select.Option, Object.assign({ value: "ACTIVE" }, { children: "Active" }), "ACTIVE"), _jsx(Ant.Select.Option, Object.assign({ value: "PROCESSING" }, { children: "Processing" }), "PROCESSING"), _jsx(Ant.Select.Option, Object.assign({ value: "DONE" }, { children: "Done" }), "DONE")] }), void 0) }), void 0)),
            },
            {
                id: "email",
                label: t("management.search_campaign_journalists.fields.email"),
                name: ["email"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Input, {}, void 0) }), void 0)),
            },
            {
                id: "confidenceScore",
                label: t("management.search_campaign_journalists.fields.confidenceScore"),
                name: ["confidenceScore"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(Ant.Slider, { range: true, step: 10, min: 0, max: 100000 }, void 0) }), void 0)),
            },
            {
                id: "isValidated",
                label: t("management.search_campaign_journalists.fields.isValidated"),
                name: ["isValidated"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsxs(Ant.Radio.Group, { children: [_jsx(Ant.Radio, Object.assign({ value: false }, { children: "No" }), 0), _jsx(Ant.Radio, Object.assign({ value: true }, { children: "Yes" }), 1)] }, void 0) }), void 0)),
            },
            {
                id: "searchCampaignId",
                label: t("management.search_campaign_journalists.fields.searchCampaign"),
                name: ["searchCampaignId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SearchCampaignsCollection, field: "name", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "searchCampaignUrlId",
                label: t("management.search_campaign_journalists.fields.searchCampaignUrl"),
                name: ["searchCampaignUrlId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SearchCampaignUrlsCollection, field: "url", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
            {
                id: "searchCampaignTermId",
                label: t("management.search_campaign_journalists.fields.searchCampaignTerm"),
                name: ["searchCampaignTermId"],
                render: (props) => (_jsx(Ant.Form.Item, Object.assign({}, props, { children: _jsx(UIComponents.RemoteSelect, { collectionClass: SearchCampaignTermsCollection, field: "searchTerm", placeholder: "Please select an option", mode: "multiple" }, void 0) }), void 0)),
            },
        ]);
    }
};
SearchCampaignJournalistListFiltersForm = __decorate([
    Service({ transient: true })
], SearchCampaignJournalistListFiltersForm);
export { SearchCampaignJournalistListFiltersForm };
