import { SearchCampaignUrlsList } from "../components/List/SearchCampaignUrlsList";
import { SearchCampaignUrlsCreate } from "../components/Create/SearchCampaignUrlsCreate";
import { SearchCampaignUrlsEdit } from "../components/Edit/SearchCampaignUrlsEdit";
import { SearchCampaignUrlsView } from "../components/View/SearchCampaignUrlsView";
import { SettingFilled } from "@ant-design/icons";
export const SEARCH_CAMPAIGN_URLS_LIST = {
    path: "/admin/search-campaign-urls",
    component: SearchCampaignUrlsList,
    menu: {
        key: "SEARCH_CAMPAIGN_URLS_LIST",
        label: "management.search_campaign_urls.menu.title",
        icon: SettingFilled,
    },
};
export const SEARCH_CAMPAIGN_URLS_CREATE = {
    path: "/admin/search-campaign-urls/create",
    component: SearchCampaignUrlsCreate,
};
export const SEARCH_CAMPAIGN_URLS_EDIT = {
    path: "/admin/search-campaign-urls/:id/edit",
    component: SearchCampaignUrlsEdit,
};
export const SEARCH_CAMPAIGN_URLS_VIEW = {
    path: "/admin/search-campaign-urls/:id/view",
    component: SearchCampaignUrlsView,
};
